import { useStaticQuery, graphql } from 'gatsby';

const QueryBlog = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          limit: 4
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { templateKey: { eq: "BlogPost-page" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                author
                date(formatString: "DD MMMM YYYY", locale: "de-DE")
                category
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark;
};

export default QueryBlog;

import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import SYSTEM_STRINGS from '../../../utility/data/system/systemStrings.yaml';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema } from '../../../global';
import { Headline } from '../../../components/cvt/data-display/headline';
import { Hexagon } from '../../../components/cvt/data-display/hexagon';

/*---Components---*/
import { LinkComponent } from '../../../components/linkComponent';

/*---Objects---*/
import { IconComponents } from '../../../global/sharedObjects';

const HexAuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 2em;
`;

const HexFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BlogPostCardHome = ({
  title,
  slug,
  date,
  textbody,
  alt,
  category,
}: {
  title: string;
  slug: string;
  date: string;
  textbody: string;
  alt: string;
  category: string;
}) => {
  const styles = {
    root: {
      width: '22em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0 1em',
    },
    hexagon: {
      base: {
        color: CvtColorSchema.white,
        fontSize: 30,
      },
    },
  };
  let icon: any;
  switch (category) {
    case 'technology':
      icon = IconComponents.Terminal;
      break;
    case 'news':
      icon = IconComponents.InsertComment;
      break;
    case 'consulting':
      icon = IconComponents.AssignmentTurnedIn;
      break;
    default:
      icon = IconComponents.LocalLibrary;
      break;
  }

  return (
    <Box component="div" sx={styles.root}>
      <HexAuthorContainer>
        <HexFlex>
          <Link to={slug}>
            <Hexagon
              componentIcon={icon}
              bgColor={CvtColorSchema.yellow}
              styleComponentIcon={styles.hexagon.base}
              size={1.5}
              alt={alt}
              clickable={true}
              invert={false}
              rotate={false}
              margin={'30%'}
            />
          </Link>
        </HexFlex>
      </HexAuthorContainer>

      <Headline text={title} variant={'h3'} />
      {date}
      <div>{textbody}</div>
      <LinkComponent color={CvtColorSchema.white} linkTo={slug}>
        {SYSTEM_STRINGS.GENERAL.WORDS.MORE +
          SYSTEM_STRINGS.GENERAL.WORDS.TRIPLE_DOTS}
      </LinkComponent>
    </Box>
  );
};

/**
 * @description Returns a preview for each blog-entry.
 * @see /index.js
 */

export default BlogPostCardHome;

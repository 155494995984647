import React from 'react';

/*---Material---*/
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, DeviceSizes } from '../../../global';

/*---Component---*/
import { LinkComponent } from '../../../components/linkComponent';

/*---Global---*/
import { SystemObj } from '../../../global/sharedObjects';
import SYSTEM_STRINGS from '../../../utility/data/system/systemStrings.yaml';

const CardKarriereHome = () => {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [`@media screen and (max-width: ${DeviceSizes.tablet})`]: {
        flexDirection: 'column',
      },
    },
    karriereTitle: {
      color: CvtColorSchema.white,
      fontWeight: 600,
      padding: ' 0 1em 1em 0',
      [`@media screen and (max-width: ${DeviceSizes.tablet})`]: {
        padding: ' 0 1em 0 0',
      },
    },
    jobOffContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      padding: '1em 0',
    },
  } as const;

  return (
    <Box component="div" sx={styles.root}>
      <Box component="div" sx={styles.jobOffContainer}>
        <Box component="div" sx={styles.karriereTitle}>
          {SYSTEM_STRINGS.COMPONENTS.KARRIERE.NO_JOB_OPP}
        </Box>
        <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.KARRIERE}>
          {SYSTEM_STRINGS.GENERAL.WORDS.MORE}
        </LinkComponent>
      </Box>
    </Box>
  );
};

/**
 * @description Returns a preview for the home-page .
 * @see /index.js
 */

export default CardKarriereHome;

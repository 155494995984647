import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema } from '../../../global/cvt-obj';

/*---Components---*/
import { Hexagon } from '../../../components/cvt/data-display/hexagon';

/*---Objects---*/
import { IconComponents } from '../../../global/sharedObjects';
import { HexagonCategoryType } from '../../../global/types';

const BlogCategorySelectionSmall = ({
  selectedCategory,
  linkTextColor,
}: {
  selectedCategory: string;
  linkTextColor: string;
}) => {
  const styles = {
    container: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: '1em 0 0 0',
      },
    },
    hexagon: {
      base: {
        color: CvtColorSchema.white,
        fontSize: 30,
      },
      links: {
        textDecoration: 'none',
        color: linkTextColor,
      },
      subheadline: {
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: '0.5em',
      },
    },
  } as const;

  const listHexagonContent: HexagonCategoryType[] = [
    {
      icon: IconComponents.LocalLibrary,
      alt: 'LocalLibrary',
      link: '/Blog',
      subtext: 'Alle',
      comparableSelectedCategory: 'none',
    },
    {
      icon: IconComponents.Terminal,
      alt: 'Terminal',
      link: '/Blog/Technologie',
      subtext: 'Technologie',
      comparableSelectedCategory: 'technology',
    },
    {
      icon: IconComponents.AssignmentTurnedIn,
      alt: 'FactCheck',
      link: '/Blog/Beratung',
      subtext: 'Beratung',
      comparableSelectedCategory: 'consulting',
    },
    {
      icon: IconComponents.InsertComment,
      alt: 'InsertComment',
      link: '/Blog/Neuigkeiten',
      subtext: 'Neuigkeiten',
      comparableSelectedCategory: 'news',
    },
  ];

  const createHexagons = (list: HexagonCategoryType[]) => {
    return list.map((item: HexagonCategoryType, index: number) => {
      return (
        <Link
          to={item.link}
          rel="next"
          style={styles.hexagon.links}
          key={`${item.alt}-${index}`}
        >
          <Hexagon
            componentIcon={item.icon}
            bgColor={
              selectedCategory === item.comparableSelectedCategory
                ? CvtColorSchema.bgDarkGray
                : CvtColorSchema.yellow
            }
            styleComponentIcon={styles.hexagon.base}
            size={2}
            alt={item.alt}
            clickable={true}
            invert={false}
            rotate={false}
            margin={'30%'}
          />
          <Box component="div" sx={styles.hexagon.subheadline}>
            {item.subtext}
          </Box>
        </Link>
      );
    });
  };

  return (
    <Box component="div" sx={styles.container.root}>
      {createHexagons(listHexagonContent)}
    </Box>
  );
};

export default BlogCategorySelectionSmall;

import React from 'react';
import PropTypes from 'prop-types';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { useLocation } from '@reach/router';
import { Box } from '@mui/material';

/*---Queries---*/
import { graphql } from 'gatsby';
import queryBlog from './Index-query-blog';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '../../global';
import { Headline } from '../../components/cvt/data-display/headline';
import { BlockStandard } from '../../components/cvt/layout/block-standard';
import { HtmlContent } from '../../components/basic/html-content';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import CardKunden from '../../components/Kunden_comps/cardKunden';
import { LinkComponent } from '../../components/linkComponent';
import CardSocialmedia from '../../components/Socialmedia_comps/cardSocialmediaHome';
import CardKarriereHome from '../Karriere-page/Karriere_comps/cardKarriereHome';
import BlogPostCardHome from '../Blog-page/Blog_comps/blogPostCardHome';
import BlogCategorySelectionSmall from '../Blog-page/Blog_comps/blogCategorySelectionSmall';
import Carousel from '../../components/carousel';
import { HexagonCard } from '../../components/hexagonCard';
import PageblockIconsFlex from '../../components/pageBlockIconsFlex';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

/*---Images---*/
import ImageHomeNerds from '../../images/Pics/pic_home_nerds.svg';
import ImageHomeUnternehmen from '../../images/Pics/pic_home_unternehmen.svg';
import ImageWallBackground from '../../images/Pics/pic_home_social_JPG.jpg';
import ImageBlog from '../../images/Pics/pic_home_blog.svg';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const IndexPageTemplate = ({
  indexCarouselData,
  indexContentKompetenzfelder,
  indexContentUnternehmen,
  indexContentKarriere,
  indexContentSocialmedia,
}: {
  indexCarouselData: any;
  indexContentKompetenzfelder: any;
  indexContentUnternehmen: any;
  indexContentKarriere: any;
  indexContentSocialmedia: any;
}) => {
  return (
    <section className="section section--gradient">
      {indexCarouselData.map((item: any) => {
        return (
          <>
            <div
              className="full-width-image-container margin-top-0"
              style={{
                backgroundImage: `url(${
                  !!item.image.childImageSharp
                    ? item.image.childImageSharp.fluid.src
                    : item.image
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            >
              <h1
                style={{
                  color: 'white',
                }}
              >
                {item.title}
              </h1>
              <h3
                style={{
                  color: 'white',
                }}
              >
                {item.subtitle}
              </h3>
            </div>
            <hr />
          </>
        );
      })}

      <h1>{indexContentKompetenzfelder.headline}</h1>
      <div
        className="container"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            indexContentKompetenzfelder.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </div>
      <hr />
      <div
        className="container"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        Blog-Content
      </div>
      <hr />
      <div
        className="container"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        Kunden-Content
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!indexContentKarriere.childImageSharp
              ? indexContentKarriere.childImageSharp.fluid.src
              : indexContentKarriere
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {indexContentKarriere.headline}
        </h1>
        <HtmlContent
          dangerouslySetInnerHTML={md.render(indexContentKarriere.content)}
          style={CvtBasicTheme.htmlContent.general}
        />
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!indexContentUnternehmen.childImageSharp
              ? indexContentUnternehmen.childImageSharp.fluid.src
              : indexContentUnternehmen
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {indexContentUnternehmen.headline}
        </h1>
        <HtmlContent
          dangerouslySetInnerHTML={md.render(indexContentUnternehmen.content)}
          style={CvtBasicTheme.htmlContent.general}
        />
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!indexContentSocialmedia.childImageSharp
              ? indexContentSocialmedia.childImageSharp.fluid.src
              : indexContentSocialmedia
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {indexContentSocialmedia.headline}
        </h1>
      </div>
      <hr />
      <footer
        style={{
          backgroundColor: '#eeeeee',
        }}
      >
        Footer
      </footer>
    </section>
  );
};

IndexPageTemplate.propTypes = {
  indexCarouselData: PropTypes.array,
  indexContentKompetenzfelder: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  indexContentKarriere: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.string,
  }),

  indexContentUnternehmen: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  indexContentSocialmedia: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.string,
  }),
};

const IndexPage = ({ data }: { data: any }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      marginTop: '0%',
      justifyContent: 'space-around',
      [`@media(max-width:1900px)`]: {
        '& > div': {
          flexGrow: '1',
        },
      },
      [`@media(max-width:942px)`]: {
        '& > div': {
          paddingTop: '1em',
        },
      },
    },
    break: {
      display: 'none',
      [`@media(min-device-width: 943px) and (max-width:1900px)`]: {
        display: 'flex',
        flexBasis: '100%',
        paddingTop: '1em',
        height: 0,
      },
    },
  } as const;
  const qBlog = queryBlog();
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      {/* Display Carousel Top */}
      <Carousel data={frontmatter} />
      {/* Display Kompetenzfelder */}
      <BlockStandard
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        bgColor={CvtColorSchema.white}
        zIndex={8}
        padding={PaddingObj.PaddingIdle}
        buttonLink={SYSTEM_STRINGS.LINKS.INTERN.KOMPETENZFELDER}
        buttonText={SYSTEM_STRINGS.LINKS.NAME.KOMPETENZFELDER}
      >
        <Headline
          text={frontmatter.indexContentKompetenzfelder.headline}
          variant={'h3'}
        />
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.indexContentKompetenzfelder.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        Zahlreiche renommierte{' '}
        <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.KUNDEN}>
          {SYSTEM_STRINGS.LINKS.NAME.KUNDEN}
        </LinkComponent>{' '}
        vertrauen uns, unserer Verbindlichkeit und unseren Erfahrungen in
        folgenden Bereichen:
        <PageblockIconsFlex margintop="1%">
          {frontmatter.indexContentKompetenzfelder.hexagonBlock.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={'HexagonCard-' + index}>
                  <HexagonCard
                    link={item.link}
                    graphic={
                      !item.image.childImageSharp &&
                      item.image.extension === 'svg'
                        ? item.image.publicURL
                        : null
                    }
                    alt={item.headline}
                    iconsize={'6'}
                    length={'short'}
                    headline={item.headline}
                    description={item.content}
                    linkText={SYSTEM_STRINGS.GENERAL.WORDS.MORE}
                  />
                </React.Fragment>
              );
            }
          )}
        </PageblockIconsFlex>
      </BlockStandard>

      {/* Display Blog */}
      <BlockStandard
        bgImage={ImageBlog}
        buttonLink={SYSTEM_STRINGS.LINKS.INTERN.BLOG.MAIN}
        buttonText={SYSTEM_STRINGS.LINKS.NAME.BLOG.MAIN}
        buttonBgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontBlack}
        buttonColor={CvtColorSchema.green}
      >
        <Box component="div" sx={styles.container}>
          {qBlog.edges.map(({ node }: any, index: number) => {
            return (
              <React.Fragment key={'frag-' + index}>
                <BlogPostCardHome
                  title={node.frontmatter.title}
                  slug={node.fields.slug}
                  date={node.frontmatter.date}
                  textbody={node.excerpt}
                  category={node.frontmatter.category}
                  alt={node.frontmatter.category}
                  key={'index-' + index}
                />
                {index === 1 && (
                  <Box
                    component="div"
                    sx={styles.break}
                    key={'break-' + index}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Box>
        <BlogCategorySelectionSmall linkTextColor={CvtColorSchema.white} />
      </BlockStandard>

      {/* Display Kunden */}
      <BlockStandard
        buttonLink={SYSTEM_STRINGS.LINKS.INTERN.KUNDEN}
        buttonText={SYSTEM_STRINGS.LINKS.NAME.KUNDEN}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgColor={CvtColorSchema.bgGray}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.white}
        zIndex={6}
      >
        <CardKunden />
      </BlockStandard>

      {/* Display Karriere */}
      <BlockStandard
        buttonLink={SYSTEM_STRINGS.LINKS.INTERN.KARRIERE}
        buttonText={SYSTEM_STRINGS.LINKS.NAME.KARRIERE}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={ImageHomeNerds}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.white}
        zIndex={5}
      >
        <Headline variant="h2" text="Ein ❤️ für Nerds" />
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.indexContentKarriere.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <CardKarriereHome />
      </BlockStandard>

      {/* Display Unternehmen */}
      <BlockStandard
        bgImage={ImageHomeUnternehmen}
        headline={frontmatter.indexContentUnternehmen.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={4}
        buttonLink={SYSTEM_STRINGS.LINKS.INTERN.UNTERNEHMEN}
        buttonText={SYSTEM_STRINGS.LINKS.NAME.UNTERNEHMEN}
        padding={PaddingObj.PaddingIdle}
        textAlignment={CvtBasicTheme.align.right as any}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.indexContentUnternehmen.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.UNTERNEHMEN}>
          {SYSTEM_STRINGS.GENERAL.WORDS.MORE}
        </LinkComponent>
      </BlockStandard>

      {/* Display Socialmedia */}

      <BlockStandard
        bgImage={ImageWallBackground}
        headline={frontmatter.indexContentSocialmedia.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={3}
        padding={PaddingObj.PaddingIdle}
      >
        <CardSocialmedia />
      </BlockStandard>
    </Layout>
  );
};

export default IndexPage;

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html

      frontmatter {
        indexContentKarriere {
          headline
          content
        }
        indexContentKompetenzfelder {
          headline
          content
          hexagonBlock {
            headline
            link
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        indexContentUnternehmen {
          headline
          content
        }
        indexContentSocialmedia {
          headline
        }

        pageDescription
        pageTitle
        seoKeywords
        indexCarousel {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 526, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
